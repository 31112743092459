<template>
  <div class="row p-p-0 p-m-0">
    <div class="col-md-4 p-p-0" style="border-right: 1px dotted #eee">
      <component
        v-bind:is="componentName"
        :receiptDetail="{ receiptID: receiptID }"
        v-on:searchReceiptEvent="searchReceipt"
        v-on:updateRedoReceipt="redoReceipt"
        v-on:clearCheckOutEvent="clearCheckOutScreen"
      ></component>
    </div>
    <div class="col-md-8 p-p-0 right-section">
      <div class="table-inv-list">
        <h5 class="checkout-heading">
          Invoice List ({{ customerOrders.length }})
          <small v-if="date1 != ''"> ({{ date1 }} -TO- {{ date2 }}) </small>
          <label
            @click="togglePage('SearchTransactions')"
            class="pull-right p-m-0 search-btn"
            ><i class="pi pi-search"></i> New Search</label
          >
        </h5>
        <table class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th></th>
              <th class="p-m-0 p-p-0">INV</th>
              <th class="p-m-0 p-p-0">CUS NAME</th>
              <th class="p-m-0 p-p-0">UNIT</th>
              <th class="p-m-0 p-p-0">BALANCE</th>
              <th class="p-m-0 p-p-0">STATUS</th>
              <th class="p-m-0 p-p-0">IN DATE</th>
              <th class="p-m-0 p-p-0">DUE DATE</th>
              <th class="p-m-0 p-p-0">PAY METHOD</th>
              <th class="p-m-0 p-p-0">Notify</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orders in sortReceipts(customerOrders)" :key="orders">
              <td class="p-m-0 p-p-0">
                <input
                  type="radio"
                  onclick=""
                  name="invoice_list"
                  style="height: 25px; width: 25px"
                  value=""
                  @click="getInvDetails(orders.id, orders.accountType,paymentMethod(orders.invoicePayment, orders.status))"
                />
              </td>
              <td class="p-m-0 p-p-0">{{ orders.id }}</td>
              <td class="p-m-0 p-p-0">
                <a
                  href="javascript:void(0)"
                  @click="openCustomerProfile(orders.cusId)"
                  >{{
                    minCustomerName(orders.firstName + " " + orders.lastName)
                  }}</a
                >
              </td>
              <td class="p-m-0 p-p-0">{{ orders.totalQty }}</td>
              <td class="p-m-0 p-p-0" @click="togglePage('ReceiptPayment')">
                <a
                  :class="{
                    redColor:getBalanceAmt(orders.totalBill - orders.totalPaid) != 0,
                    greenColor:
                      getBalanceAmt(orders.totalBill - orders.totalPaid) == 0,
                  }"
                  href="javascript:void(0)"
                >
                  $ {{ getBalanceAmt(orders.totalBill - orders.totalPaid) }}
                </a>
              </td>
              <td class="p-m-0 p-p-0">{{ orders.status }}</td>
              <td class="p-m-0 p-p-0">{{ formatDate(orders.date) }} / {{ formatTime(orders.timeEntrance) }}</td>
              <td class="p-m-0 p-p-0">{{ formatDate(orders.dueDate) }}</td>
              <td class="p-m-0 p-p-0">
                {{ paymentMethod(orders.invoicePayment, orders.status) }}
              </td>
              <td class="p-m-0 p-p-0 text-center">
                <Button
                  :class="{
                    'p-button-danger': orders.notificationStatus == 'waiting',
                    'p-button-success': orders.notificationStatus != 'waiting',
                  }"
                  @click="confirmEmailNotification"
                  class="p-mr-1"
                  icon="pi pi-envelope"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-checkout-list">
        <h5 class="checkout-heading">
          <i class="pi pi-check-circle"></i> Check out list
        </h5>
        <table class="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th class="p-m-0 p-p-0 text-center">
                <Button @click="checkAllBoxes()" icon="pi pi-check-circle"  class="p-button-success"/>
              </th>
              <th class="p-m-0 p-p-0">INV</th>
              <th class="p-m-0 p-p-0">DESCRIPTION</th>
              <th class="p-m-0 p-p-0">UNIT</th>
              <th class="p-m-0 p-p-0">STATUS</th>
              <th class="p-m-0 p-p-0">PROGRESS</th>
            </tr>
          </thead>
          <tbody v-if="receiptID != ''">
            <tr v-for="items in receiptItems.slice().reverse()" :key="items">
              <td class="p-m-0 p-p-0 text-center">
                <Checkbox
                  v-model="checkedItems"
                  :disabled="disableCheckedItem == true"
                  :value="items"
                  style="height: 20px; width: 20px"
                />
              </td>
              <td class="p-m-0 p-p-0">{{ items.invId }}</td>
              <td class="p-m-0 p-p-0">{{ items.serviceName }}</td>
              <td class="p-m-0 p-p-0">{{ items.qty }}</td>
              <td class="p-m-0 p-p-0">
                {{ items.checkStatus.toUpperCase() }}
              </td>
              <td style="color: #c00" class="p-m-0 p-p-0">
                {{ items.workingStatus.toUpperCase() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="co-customer-last">
        <table class="table">
          <tr>
            <td
              @click="showPrintReceipt()"
              class="co-customer-btn co-customer-orange"
            >
              <i class="pi pi-print"></i>
              <span class="responsive-btn-text"> Print</span>
            </td>
            <td
              @click="cancelReceipt()"
              class="co-customer-btn co-customer-red"
            >
              <i class="pi pi-times"></i>
              <span class="responsive-btn-text">  VOID</span>
            </td>
            <td
              @click="loadRedoReceipt()"
              class="co-customer-btn co-customer-blue"
            >
              <i class="pi pi-undo" aria-hidden="true"></i>
              <span class="responsive-btn-text"> Redo</span>
            </td>
            <td
              @click="togglePage('RefundReceipt')"
              class="co-customer-btn co-customer-dark-gray"
            >
              <i class="pi pi-directions-alt" aria-hidden="true"></i>
              <span class="responsive-btn-text"> Refund</span>
            </td>
            <td
              @click="togglePage('BuyBack')"
              class="co-customer-btn co-customer-black"
            >
              <i class="pi pi-spinner"></i>
              <span class="responsive-btn-text"> BB</span>
            </td>
            <td
              @click="togglePage('ReceiptHistory')"
              class="co-customer-btn co-customer-pink"
            >
              <i class="pi pi-chart-bar"></i>
              <span class="responsive-btn-text"> History</span>
            </td>
            <td @click="takeSignature" class="co-customer-btn btn-purple">
              <i class="pi pi-file" aria-hidden="true"></i>
              <span class="responsive-btn-text"> Sign</span>
            </td>
            <td @click="editReceipt" class="co-customer-btn co-customer-pink">
              <i class="pi pi-pencil" aria-hidden="true"></i>
              <span class="responsive-btn-text"> Edit</span>
            </td>
            <td @click="confirmCheckOut" class="co-customer-btn co-customer-ok">
              <i class="pi pi-check-circle"></i>
              <span class="responsive-btn-text"> OK</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="cancelDialog"
    :style="{ width: '450px' }"
    header="Confirm"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span
        >Are you sure to <span style="color:#c00;"> VOID ALL </span> the items of this invoice # <b>{{ receiptID }}</b> ?</span
      >
      <br />
      <small>If you want to void a single item you can edit the invoice.</small>  
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="cancelDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-danger"
        @click="cancelTheConfirmedReceipt"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="printDialog"
    :style="{ width: '900px' }"
    header="Print Receipts"
  >
    <div class="row">
      <div class="p-col-12 text-center">
        <Button
          label="Customer Receipt"
          @click="generateReceipt('customer')"
          icon="pi pi-print"
          class="p-button-lg p-button-primary p-mr-4"
        />
        <Button
          label="Store Receipt"
          icon="pi pi-print"
          @click="generateReceipt('store')"
          class="p-button-lg p-button-success p-mr-4"
        />
        <Button
          label="Dry Clean Tags"
          icon="pi pi-print"
          @click="generateReceipt('tags')"
          class="p-button-lg p-button-warning p-mr-4"
        />
        <Button
          label="Wavier Receipt"
          icon="pi pi-print"
          @click="generateReceipt('wavier')"
          class="p-button-lg p-button-secondary"
        />
      </div>
    </div>
  </Dialog>

  <Dialog
    v-model:visible="checkoutConfirmDialog"
    :style="{ width: '400px' }"
    header="Confirm Checkout"
  >
    <div class="row">
      <div class="p-col-12 text-center">
        <b>Do you really want to checkout this receipt ? </b>
      </div>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="checkoutConfirmDialog = false"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-success"
        @click="validateCheckout"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="confirmEmail"
    :style="{ width: '400px' }"
    header="Confirm Notification"
  >
    <div class="row">
      <div class="p-col-12 text-center">
        <b>Do you really want to send the notification to customer ? </b>
      </div>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="confirmEmail = false"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-success"
        @click="sendEmailNotification()"
      />
    </template>
  </Dialog>
  <PaymentScreen
    :receiptDetail="{
      dialogStatus: paymentDialog,
      itemSource: 'Checkout',
      restriction: 'Yes',
      dialogTilte: dialogTilte,
      customerID: customerID,
      customerName: customerName,
      needlePoints: CustomerNeedles,
      closeConfirmation: confirmCloseScreen,
    }"
    v-on:closePaymentScreenEvent="closePaymentScreen"
    v-on:getProceededPaymentsEvent="checkoutReceiptPayment"
  />
  <CustomerDialog
    :customerDetail="{
      status: customerStatus,
      CustomerPhone: '',
      customerProfile: customerProfile,
      permission: permission,
      title: dialogTitle,
      customerId: customerID,
      customerStoreID: customerStoreID,
      regionList: regionList,
    }"
    v-on:updateCustomerStatus="updateCustomerStatus"
  />
  <SignatureDailog
    :receiptDetails="{
      receiptID: receiptID,
      signatureDialog: signatureDialog,
    }"
    v-on:updateSignatureStatus="updateSignatureStatus"
  />
</template>

<script lang="ts">
import { mixins, Options } from "vue-class-component";
import SearchTransactions from "../../components/SearchTransactions.vue";
import SignatureDailog from "../../components/SignatureDailog.vue";
import PrintTransactions from "../../components/PrintTransactions.vue";
import CheckoutReceipt from "../../components/CheckoutReceipt.vue";
import ReceiptHistory from "../../components/ReceiptHistory.vue";
import RedoReceipt from "../../components/RedoReceipt.vue";
import BuyBack from "../../components/BuyBack.vue";
import RefundReceipt from "../../components/RefundReceipt.vue";
import moment from "moment";
import { useStore, ActionTypes } from "../../store";
import Transaction from "../../service/Transaction";
import Toaster from "../../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../../components/PaymentScreen.vue";
import Customer from "../../service/Customer";
import ReceiptPayment from "../../components/ReceiptPayment.vue";
import CustomerDialog from "../../components/CustomerDialog.vue";
import { CustomerOrders, ReceiptItems } from "../transaction/ITransaction";
import router from "../../router";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {
    SignatureDailog,
    SearchTransactions,
    PrintTransactions,
    CheckoutReceipt,
    ReceiptHistory,
    RedoReceipt,
    RefundReceipt,
    PaymentScreen,
    ReceiptPayment,
    BuyBack,
    CustomerDialog,
  },
  title: "Transactions",
})
export default class Transactions extends mixins(UtilityOptions){
  private store = useStore();
  private customerService;
  private dialogTilte = "Checkout Payments";
  private componentName = "SearchTransactions";
  private checkoutConfirmDialog = false;
  private disableCheckedItem = false;
  private toggleCheckBox = false;
  private signatureDialog = false;
  private paymentDialog = false;
  private cancelDialog = false;
  private confirmEmail = false;
  private confirmCloseScreen = false;
  private printDialog = false;
  private txnService;
  private toast;
  private activePage = "search";
  private accountType = "";
  private date1 = "";
  private date2 = "";
  private receiptID = "";
  private totalBalance = 0;
  private customerID = "";
  private customerName = "";
  private CustomerNeedles = 0;
  private customerOrders: CustomerOrders[] = [];
  private receiptItems: ReceiptItems[] = [];
  private checkedItems: ReceiptItems[] = [];
  private customerStatus = "";
  private customerStoreID = "";
  private regionList = [];
  private customerProfile = [];
  private permission = "";
  private dialogTitle = "";
  private paymentMethodName = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new Transaction();
    this.customerService = new Customer();
    this.toast = new Toaster();
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  updateSignatureStatus() {
    this.signatureDialog = false;
  }

  openCustomerProfile(id) {
    if (id != "") {
      this.dialogTitle = "Customer Profile";
      this.permission = "yes";
      this.customerStatus = "updateCustomer";
      this.customerID = id;

      this.customerService.openDialog().then((data) => {
        this.regionList = this.camelizeKeys(data.regions);
      });

      this.customerService.getItem(id).then((res) => {
        this.customerProfile = this.camelizeKeys(res);
      });
    }
  }

  togglePage(compName) {
    if (this.receiptID != "") {
      this.componentName = compName;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  takeSignature() {
    if (this.receiptID != "") {
      this.signatureDialog = true;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  confirmCheckOut() {
    this.checkoutConfirmDialog = true;
  }

  get pageStatus() {
    return this.activePage;
  }

  searchReceipt(res) {
    this.customerOrders = [];
    this.date1 = this.formatDate(res.date1);
    this.date2 = this.formatDate(res.date2);
    this.customerOrders = this.camelizeKeys(res.customer_orders);
  }

  formatDate(value) {
    let reFormatedDate = "";

    if (value) {
      reFormatedDate = moment(value).format("DD-MM-YYYY");
    }

    return reFormatedDate;
  }

  paymentMethod(method, status) {
    let type = "";
    if (status != "VOID") {
      if (method.length > 1) {
        type = "Split";
      } else if (method.length == 1) {
        type = method[0].paymentType;
      } else {
        type = "Pay Later";
      }
    } else {
      type = status;
    }

    return type;
  }

  getInvDetails(receiptID, accountType, paymentMethod) {
    this.disableCheckedItem = false;
    this.store.dispatch(ActionTypes.GET_RECEIPT_ID, receiptID);

    this.receiptID = receiptID;
    this.accountType = accountType;
    this.paymentMethodName = paymentMethod;

    this.txnService.getInvReceipts(receiptID).then((data) => {
      const receiptItems = this.camelizeKeys(data["sales_order_items"]);
      this.receiptItems = receiptItems;
    });

    //previewReceipt
    this.componentName = "CheckoutReceipt";

    //MAKE LIST EMPTY
    this.checkedItems = [];
  }

  cancelReceipt() {
    if (this.receiptID != "") {
        if(this.paymentMethodName == 'Pay Later' && this.checkItemsStatus == 'rack')
        {
          this.cancelDialog = true; 
        }
        else
        {
          this.toast.showError("Payment method should be Pay Later & Item status should be on Rack");  
        }
    }
    else
    {
      this.toast.showError("Please choose any order");  
    }
  }

  get checkItemsStatus()
  {
    let status = this.receiptItems.length > 0 ? this.receiptItems[0].workingStatus : '';
    
    this.receiptItems.forEach(e => {
        if(e.workingStatus != status)
        {
          status = 'invalid';
        }  
    });
    return status;
  }

   get chosenItemsStatus()
  {
    let status = this.checkedItems.length > 0 ? this.checkedItems[0].workingStatus : '';
    
    this.checkedItems.forEach(e => {
        if(e.workingStatus != status)
        {
          status = 'invalid';
        }  
    });
    return status;
  }

  showPrintReceipt() {
    if (this.receiptID != "") {
      this.printDialog = true;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  cancelTheConfirmedReceipt() {
    this.cancelDialog = false;
    this.txnService.cancelReceipt(this.receiptID).then((res) => {
      this.toast.handleResponse(res);
      this.clearCheckOutScreen();
    });
  }

  generateReceipt(source) {
    this.printDialog = false;

    this.txnService.generateReceipt(source, this.receiptID).then((res) => {
      if (res == "success") {
        this.toast.showSuccess("Receipt Generated Successfully");
      }
      else if(res == 'empty')
      {
        this.toast.showError("No wavier found to print");
      }
      else {
        this.toast.showError("Cannot find the Printer");
      }
    });
  }

  loadRedoReceipt()
  {
    if (this.receiptID != "") {
        if(this.chosenItemsStatus == 'ready')
        {
          this.disableCheckedItem = true;
          this.componentName = 'RedoReceipt';
        }
        else
        {
          this.toast.showError("Item status should be on Ready");  
        }
    }
    else
    {
      this.toast.showError("Please choose any order");  
    }
  }

  redoReceipt(obj) {
    if (this.checkedItems.length > 0) {
      this.txnService
        .redoReceipt(obj, this.checkedItems, this.receiptID)
        .then((res) => {
          this.toast.handleResponse(res);
          this.clearCheckOutScreen();
        });
    } else {
      this.toast.showError("Please choose the services to redo");
    }
  }

  clearCheckOutScreen() {
    this.customerOrders = [];
    this.receiptItems = [];
    this.date1 = "";
    this.date2 = "";
    this.componentName = "SearchTransactions";
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  validateCheckout() {
    let readyItems = 0;

    if (this.checkedItems.length > 0) {
      this.checkedItems.forEach((f) => {
        //CHECK IF STATUS IS READY AND IN OTHERWISE DONT ALLOW
        if (
          f.workingStatus.toUpperCase() == "READY" &&
          (f.checkStatus.toUpperCase() == "IN" ||
            f.checkStatus.toUpperCase() == "REDO")
        ) {
          readyItems++;
        }
      });

      if (readyItems == this.checkedItems.length) {
        this.checkoutConfirmDialog = false;

        //CALCULATE RECEIPT BALANCE AND SET CUSTOMER ID AND NAME
        const amountBal = this.checkReceiptBalance();

        if (amountBal == 0 || this.accountType == "Charge") {
          this.checkoutReceiptPayment([]);
        } else {
          this.txnService.getCustomerNeedles(this.customerID).then((res) => {
            this.CustomerNeedles = Number(res);
          });

          //OPEN PAYMENT SCREEN
          this.store.dispatch(ActionTypes.TOTAL_BILL, this.totalBalance);
          this.paymentDialog = true;
        }
      } else {
        this.checkoutConfirmDialog = false;
        this.toast.showError(
          "Receipt items should be READY AND Status should be IN to checkout"
        );
      }
    } else {
      this.checkoutConfirmDialog = false;
      this.toast.showError("Please choose any receipt item");
    }
  }

  //SEND CHECKOUT REQUEST
  checkoutReceiptPayment(paymentList) {

    const drawerStatus = this.shouldOpenDrawer(paymentList);
    this.confirmCloseScreen = true;
    this.paymentDialog = false;
    this.txnService
      .checkoutReceipt(
        paymentList,
        this.checkedItems,
        this.receiptID,
        this.totalBalance,
        drawerStatus
      )
      .then((res) => {
        this.toast.handleResponse(res);
        this.clearCheckOutScreen();
      });
  }

  checkReceiptBalance() {
    let balanceAmount = 0;

    this.customerOrders.forEach((e) => {
      if (e.id == this.receiptID) {
        this.customerID = e.cusId;
        this.customerName = e.firstName + " " + e.lastName;
        const balance = Number(e.totalBill) - Number(e.totalPaid);

        if (balance <= 0.03) {
          balanceAmount = 0;
        } else {
          balanceAmount = balance;
        }

        this.totalBalance = Number(balanceAmount);
      }
    });
    return balanceAmount;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  confirmEmailNotification() {
    this.confirmEmail = true;
  }

  sendEmailNotification() {
    if (this.receiptID != "") {
      this.confirmEmail = false;
      this.txnService.emailNotification(this.receiptID).then((res) => {
        this.toast.handleResponse(res);
        this.clearCheckOutScreen();
      });
    } else {
      this.toast.showInfo("Please choose any order");
      this.confirmEmail = false;
    }
  }

  minCustomerName(name) {
    let sub = "";

    if (name.length < 15) {
      sub = name;
    } else {
      sub = name.substring(0, 15) + "...";
    }

    return sub;
  }

  sortReceipts(receipts) {
    receipts.sort(function (left, right) {
      return right.id - left.id;
    });

    return receipts;
  }

  checkAllBoxes() {
    this.toggleCheckBox = !this.toggleCheckBox;

    if (this.toggleCheckBox == true) {
      this.checkedItems = this.receiptItems;
    } else {
      this.checkedItems = [];
    }
  }

  editReceipt()
  {
    if (this.receiptID != "") {
        if(this.paymentMethodName == 'Pay Later' && this.checkItemsStatus == 'rack')
        {
          router.push({ name: "update-receipt", params: { receiptID: this.receiptID } });
        }
        else
        {
          this.toast.showError("Payment method should be Pay Later & Item status should be on Rack");  
        }
    }
    else
    {
      this.toast.showError("Please choose any order");  
    }
  }

  get isItVoidable()
  {
    return this.paymentMethodName;
  }

  getBalanceAmt(balance)
  {
    balance = Number(balance);

    if(balance <= 0.02)
    {
      balance = 0;
    }

    return this.formatAmount(balance);
  }
}
</script>

<style scoped>

.search-btn {
  background-color: green;
  padding:0px 3px;
}

.search-btn:hover {
  cursor: pointer;
}

.right-section {
  display: flex;
  flex-direction: column;
  height: 92.3vh;
}

.table-inv-list {
  margin: 0px;
  padding: 0;
  height: 30vh;
  min-height: 30vh;
  flex-grow: 1;
  overflow-y: scroll;
}

.table-checkout-list {
  margin: 0px;
  padding: 0;
  height: 27vh;
  min-height: 27vh;
  flex-grow: 1;
  overflow-y: scroll;
}

.co-customer-last {
  height: 8vh;
  min-height: 8vh;
}

.co-customer-btn {
  line-height: 8vh;
  font-size: 20px;
  color: #fff;
  padding: 0;
  text-align: center;
}

.co-customer-btn i {
  font-size: 20px;
  font-weight: bold;
}

.co-customer-btn:hover {
  background-color: #eee;
  color: #004c97;
  cursor: pointer;
}

.co-customer-ok {
  background-color: #00b100;
  border-left: 1px dotted #fff;
}

.co-customer-orange {
  background-color: #ffa90c;
  border-left: 1px dotted #fff;
}

.co-customer-blue {
  background-color: #004c97;
  border-left: 1px dotted #fff;
}

.co-customer-black {
  background-color: #343a40;
  border-left: 1px dotted #fff;
}

.co-customer-red {
  background-color: #b10000;
  border-left: 1px dotted #fff;
}

.co-customer-gray {
  background-color: brown;
  border-left: 1px dotted #fff;
}

.co-customer-pink {
  background-color: #ff5722;
  border-left: 1px dotted #fff;
}

.co-customer-dark-gray {
  background-color: #807f86;
  border-left: 1px dotted #fff;
}

.btn-purple {
  background-color: #7616b5e0;
  border-left: 1px dotted #fff;
}

.p-button.p-button-icon-only {
  padding: 0.1rem 0;
}

@media only screen and (max-width: 1300px) {
  .responsive-btn-text {
    display: none;
  }
}

.redColor {
  color: #c00;
}

.greenColor {
  color: green;
}
</style>