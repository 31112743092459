
import { mixins, Options } from "vue-class-component";
import SearchTransactions from "../../components/SearchTransactions.vue";
import SignatureDailog from "../../components/SignatureDailog.vue";
import PrintTransactions from "../../components/PrintTransactions.vue";
import CheckoutReceipt from "../../components/CheckoutReceipt.vue";
import ReceiptHistory from "../../components/ReceiptHistory.vue";
import RedoReceipt from "../../components/RedoReceipt.vue";
import BuyBack from "../../components/BuyBack.vue";
import RefundReceipt from "../../components/RefundReceipt.vue";
import moment from "moment";
import { useStore, ActionTypes } from "../../store";
import Transaction from "../../service/Transaction";
import Toaster from "../../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../../components/PaymentScreen.vue";
import Customer from "../../service/Customer";
import ReceiptPayment from "../../components/ReceiptPayment.vue";
import CustomerDialog from "../../components/CustomerDialog.vue";
import { CustomerOrders, ReceiptItems } from "../transaction/ITransaction";
import router from "../../router";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {
    SignatureDailog,
    SearchTransactions,
    PrintTransactions,
    CheckoutReceipt,
    ReceiptHistory,
    RedoReceipt,
    RefundReceipt,
    PaymentScreen,
    ReceiptPayment,
    BuyBack,
    CustomerDialog,
  },
  title: "Transactions",
})
export default class Transactions extends mixins(UtilityOptions){
  private store = useStore();
  private customerService;
  private dialogTilte = "Checkout Payments";
  private componentName = "SearchTransactions";
  private checkoutConfirmDialog = false;
  private disableCheckedItem = false;
  private toggleCheckBox = false;
  private signatureDialog = false;
  private paymentDialog = false;
  private cancelDialog = false;
  private confirmEmail = false;
  private confirmCloseScreen = false;
  private printDialog = false;
  private txnService;
  private toast;
  private activePage = "search";
  private accountType = "";
  private date1 = "";
  private date2 = "";
  private receiptID = "";
  private totalBalance = 0;
  private customerID = "";
  private customerName = "";
  private CustomerNeedles = 0;
  private customerOrders: CustomerOrders[] = [];
  private receiptItems: ReceiptItems[] = [];
  private checkedItems: ReceiptItems[] = [];
  private customerStatus = "";
  private customerStoreID = "";
  private regionList = [];
  private customerProfile = [];
  private permission = "";
  private dialogTitle = "";
  private paymentMethodName = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new Transaction();
    this.customerService = new Customer();
    this.toast = new Toaster();
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  updateSignatureStatus() {
    this.signatureDialog = false;
  }

  openCustomerProfile(id) {
    if (id != "") {
      this.dialogTitle = "Customer Profile";
      this.permission = "yes";
      this.customerStatus = "updateCustomer";
      this.customerID = id;

      this.customerService.openDialog().then((data) => {
        this.regionList = this.camelizeKeys(data.regions);
      });

      this.customerService.getItem(id).then((res) => {
        this.customerProfile = this.camelizeKeys(res);
      });
    }
  }

  togglePage(compName) {
    if (this.receiptID != "") {
      this.componentName = compName;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  takeSignature() {
    if (this.receiptID != "") {
      this.signatureDialog = true;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  confirmCheckOut() {
    this.checkoutConfirmDialog = true;
  }

  get pageStatus() {
    return this.activePage;
  }

  searchReceipt(res) {
    this.customerOrders = [];
    this.date1 = this.formatDate(res.date1);
    this.date2 = this.formatDate(res.date2);
    this.customerOrders = this.camelizeKeys(res.customer_orders);
  }

  formatDate(value) {
    let reFormatedDate = "";

    if (value) {
      reFormatedDate = moment(value).format("DD-MM-YYYY");
    }

    return reFormatedDate;
  }

  paymentMethod(method, status) {
    let type = "";
    if (status != "VOID") {
      if (method.length > 1) {
        type = "Split";
      } else if (method.length == 1) {
        type = method[0].paymentType;
      } else {
        type = "Pay Later";
      }
    } else {
      type = status;
    }

    return type;
  }

  getInvDetails(receiptID, accountType, paymentMethod) {
    this.disableCheckedItem = false;
    this.store.dispatch(ActionTypes.GET_RECEIPT_ID, receiptID);

    this.receiptID = receiptID;
    this.accountType = accountType;
    this.paymentMethodName = paymentMethod;

    this.txnService.getInvReceipts(receiptID).then((data) => {
      const receiptItems = this.camelizeKeys(data["sales_order_items"]);
      this.receiptItems = receiptItems;
    });

    //previewReceipt
    this.componentName = "CheckoutReceipt";

    //MAKE LIST EMPTY
    this.checkedItems = [];
  }

  cancelReceipt() {
    if (this.receiptID != "") {
        if(this.paymentMethodName == 'Pay Later' && this.checkItemsStatus == 'rack')
        {
          this.cancelDialog = true; 
        }
        else
        {
          this.toast.showError("Payment method should be Pay Later & Item status should be on Rack");  
        }
    }
    else
    {
      this.toast.showError("Please choose any order");  
    }
  }

  get checkItemsStatus()
  {
    let status = this.receiptItems.length > 0 ? this.receiptItems[0].workingStatus : '';
    
    this.receiptItems.forEach(e => {
        if(e.workingStatus != status)
        {
          status = 'invalid';
        }  
    });
    return status;
  }

   get chosenItemsStatus()
  {
    let status = this.checkedItems.length > 0 ? this.checkedItems[0].workingStatus : '';
    
    this.checkedItems.forEach(e => {
        if(e.workingStatus != status)
        {
          status = 'invalid';
        }  
    });
    return status;
  }

  showPrintReceipt() {
    if (this.receiptID != "") {
      this.printDialog = true;
    } else {
      this.toast.showInfo("Please choose any order");
    }
  }

  cancelTheConfirmedReceipt() {
    this.cancelDialog = false;
    this.txnService.cancelReceipt(this.receiptID).then((res) => {
      this.toast.handleResponse(res);
      this.clearCheckOutScreen();
    });
  }

  generateReceipt(source) {
    this.printDialog = false;

    this.txnService.generateReceipt(source, this.receiptID).then((res) => {
      if (res == "success") {
        this.toast.showSuccess("Receipt Generated Successfully");
      }
      else if(res == 'empty')
      {
        this.toast.showError("No wavier found to print");
      }
      else {
        this.toast.showError("Cannot find the Printer");
      }
    });
  }

  loadRedoReceipt()
  {
    if (this.receiptID != "") {
        if(this.chosenItemsStatus == 'ready')
        {
          this.disableCheckedItem = true;
          this.componentName = 'RedoReceipt';
        }
        else
        {
          this.toast.showError("Item status should be on Ready");  
        }
    }
    else
    {
      this.toast.showError("Please choose any order");  
    }
  }

  redoReceipt(obj) {
    if (this.checkedItems.length > 0) {
      this.txnService
        .redoReceipt(obj, this.checkedItems, this.receiptID)
        .then((res) => {
          this.toast.handleResponse(res);
          this.clearCheckOutScreen();
        });
    } else {
      this.toast.showError("Please choose the services to redo");
    }
  }

  clearCheckOutScreen() {
    this.customerOrders = [];
    this.receiptItems = [];
    this.date1 = "";
    this.date2 = "";
    this.componentName = "SearchTransactions";
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  validateCheckout() {
    let readyItems = 0;

    if (this.checkedItems.length > 0) {
      this.checkedItems.forEach((f) => {
        //CHECK IF STATUS IS READY AND IN OTHERWISE DONT ALLOW
        if (
          f.workingStatus.toUpperCase() == "READY" &&
          (f.checkStatus.toUpperCase() == "IN" ||
            f.checkStatus.toUpperCase() == "REDO")
        ) {
          readyItems++;
        }
      });

      if (readyItems == this.checkedItems.length) {
        this.checkoutConfirmDialog = false;

        //CALCULATE RECEIPT BALANCE AND SET CUSTOMER ID AND NAME
        const amountBal = this.checkReceiptBalance();

        if (amountBal == 0 || this.accountType == "Charge") {
          this.checkoutReceiptPayment([]);
        } else {
          this.txnService.getCustomerNeedles(this.customerID).then((res) => {
            this.CustomerNeedles = Number(res);
          });

          //OPEN PAYMENT SCREEN
          this.store.dispatch(ActionTypes.TOTAL_BILL, this.totalBalance);
          this.paymentDialog = true;
        }
      } else {
        this.checkoutConfirmDialog = false;
        this.toast.showError(
          "Receipt items should be READY AND Status should be IN to checkout"
        );
      }
    } else {
      this.checkoutConfirmDialog = false;
      this.toast.showError("Please choose any receipt item");
    }
  }

  //SEND CHECKOUT REQUEST
  checkoutReceiptPayment(paymentList) {

    const drawerStatus = this.shouldOpenDrawer(paymentList);
    this.confirmCloseScreen = true;
    this.paymentDialog = false;
    this.txnService
      .checkoutReceipt(
        paymentList,
        this.checkedItems,
        this.receiptID,
        this.totalBalance,
        drawerStatus
      )
      .then((res) => {
        this.toast.handleResponse(res);
        this.clearCheckOutScreen();
      });
  }

  checkReceiptBalance() {
    let balanceAmount = 0;

    this.customerOrders.forEach((e) => {
      if (e.id == this.receiptID) {
        this.customerID = e.cusId;
        this.customerName = e.firstName + " " + e.lastName;
        const balance = Number(e.totalBill) - Number(e.totalPaid);

        if (balance <= 0.03) {
          balanceAmount = 0;
        } else {
          balanceAmount = balance;
        }

        this.totalBalance = Number(balanceAmount);
      }
    });
    return balanceAmount;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  confirmEmailNotification() {
    this.confirmEmail = true;
  }

  sendEmailNotification() {
    if (this.receiptID != "") {
      this.confirmEmail = false;
      this.txnService.emailNotification(this.receiptID).then((res) => {
        this.toast.handleResponse(res);
        this.clearCheckOutScreen();
      });
    } else {
      this.toast.showInfo("Please choose any order");
      this.confirmEmail = false;
    }
  }

  minCustomerName(name) {
    let sub = "";

    if (name.length < 15) {
      sub = name;
    } else {
      sub = name.substring(0, 15) + "...";
    }

    return sub;
  }

  sortReceipts(receipts) {
    receipts.sort(function (left, right) {
      return right.id - left.id;
    });

    return receipts;
  }

  checkAllBoxes() {
    this.toggleCheckBox = !this.toggleCheckBox;

    if (this.toggleCheckBox == true) {
      this.checkedItems = this.receiptItems;
    } else {
      this.checkedItems = [];
    }
  }

  editReceipt()
  {
    if (this.receiptID != "") {
        if(this.paymentMethodName == 'Pay Later' && this.checkItemsStatus == 'rack')
        {
          router.push({ name: "update-receipt", params: { receiptID: this.receiptID } });
        }
        else
        {
          this.toast.showError("Payment method should be Pay Later & Item status should be on Rack");  
        }
    }
    else
    {
      this.toast.showError("Please choose any order");  
    }
  }

  get isItVoidable()
  {
    return this.paymentMethodName;
  }

  getBalanceAmt(balance)
  {
    balance = Number(balance);

    if(balance <= 0.02)
    {
      balance = 0;
    }

    return this.formatAmount(balance);
  }
}
